<template>
  <MasterDetail
    formTitle="Cadastro de Itens Projeto Externo"
    :cols="cols"
    :opts="opts"
    :customResource="resource"
    :selectedResourceId="selectedResourceId"
    descriptionProperty="id"
    @onOpenFormDialog="onOpenFormDialog($event)"
  >
    <span v-show="valorProjetoExterno" style="font-size:16px">
      <strong>Valor utilizado da nota:</strong>
      {{ valorTotalItens | toCurrency }}
      <small>de</small>
      {{ valorProjetoExterno | toCurrency }}
    </span>
  </MasterDetail>
</template>

<script>
import MasterDetail from "@/components/master-detail.vue";
import { mapGetters } from 'vuex';
export default {
  components: {
    MasterDetail,
  },
  computed: {
    ...mapGetters(['clientId']),
    projetoId: function () {
      return this.$route.params.pId;
    },
    resource: function () {
      const resource = this.apiResource(`/v1/faturamento/projext/projeto/${this.clientId}/${this.projetoId}/itens`);
      const self = this;

      return {
        ...resource,
        get: async function (...args) {
          const response = await resource.get(...args);
          self.valorTotalItens = response.reduce((acc, { gasto_AnoBase }) => acc + (gasto_AnoBase * 1), 0);
          return response;
        }
      }
    },
    resourceProjetos: function () {
      return this.apiResource(`/v1/faturamento/projext/projeto/${this.clientId}`);
    },
    resourceTipoItem: function () {
      return this.apiResource(`/v1/faturamento/projext/tipoItem`);
    },
    valorProjetoExterno: function () {
      return this.opts.projetos.find(({ id }) => this.projetoId == id)?.gasto_AnoBase;
    },
  },
  created: function () {
    this.selectedResourceId = this.$route.query.itemId;
    this.resourceProjetos.get().then(response => {
      this.opts.projetos = response;
    });
     this.resourceTipoItem.get().then((response) => {
      this.opts.tipoitem = response;
    });
  },
  data: function () {
    const pId = this.$route.params.pId;
    return {
      selectedResourceId: null,
      valorTotalItens: 0,
      opts: {
        projetos: [],
        tipoitem: [],
      },
      cols: [
        {
          key: 'id', name: 'ID', align: 1, hideInform: true
        },
        {
          key: 'projetoextId',
          name: 'Projeto',
          defaultValue: pId ? parseInt(pId) : undefined,
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "projetos", key: "id", name: "nome_projeto" },
          editable: false,
        },
       {
          key: "tipoitemId",
          name: "Tipo Item",
          type: this.$fieldTypes.SELECT,
          rel: { to: "tipoitem", key: "id", name: "tipo_item" },
          rules: [{ rule: "required" }],
          colSize: 12,
        },
        {
          key: 'gasto_AnoBase', name: 'Gasto Ano Base',
          type: this.$fieldTypes.MONEY, nowrap: true, rules: [{ rule: "required" }],
          editable: true,
          hideInform: false,
        },
        {
          key: 'ano_base', name: 'Ano Base',
          type: this.$fieldTypes.TEXT, 
        },
        {
          key: 'observacao', name: 'Observações',
          type: this.$fieldTypes.TEXT, 
        },
        {
          key: 'criado_em', name: 'Criado Em',
          type: this.$fieldTypes.DATETIME, hideInform: true,
        },
        {
          key: 'modificado_em', name: 'Modificado Em',
          type: this.$fieldTypes.DATETIME, hideInform: true,
        },
      ],
    };
  },
}
</script>
